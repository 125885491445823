import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/SEO'
import { RichText } from 'prismic-reactjs'
import Layout from '../components/layouts'
import htmlSerializer from '../utils/htmlSerializer'

const Page = ({data}) => {
  if (!data) return null
  const document = data.allPrismicDesignSet.edges[0].node

    return (
      <Layout>
        <SEO title={RichText.asText(document.data.title)} />
        <div className="mt-5">

          <div className="page__content">
            <div className="hero__media mx-auto mb-3">
            </div>
          </div>

          <div className="page__content">
            <div class="copy__body__center">
              <div>
                <div className="eyebrow">Design</div>
                <h1 className="display__title mb-5">{RichText.asText(document.data.title)}</h1>
                <div className="description mb-5">
                  {RichText.asText(document.data.description)}
                </div>
              </div>
            </div>
            <div
              className="media__body__center design__clips">
              {document.data.designset_item.map((item) => (
                <div className="mb-5 pt-5 text-center">
                  <img
                    src={item.item_image.url}
                    className="img-fluid"
                    />
                  <div className="description pt-3">
                    {RichText.asText(item.item_description)}
                  </div>
                </div>
              ))}
            </div>

            <div className="copy__body__center mb-5">
              <div className="text-center">
                <Link to="/design">Back to Design Work</Link>
              </div>
            </div>

          </div>

        </div>
      </Layout>
    )
  }

// Query for the Blog Post content in Prismic
export const query = graphql`
query DesignQuery($uid: String) {
  allPrismicDesignSet(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        url
        uid
        type
        data {
          featured_image {
            url
          }
          title {
            type
            text
          }
          description {
            type
            text
          }
          designset_item {
            item_description {
              type
              text
            }
            item_image {
              url
            }
          }
        }
      }
    }
  }
}
`

export default Page
